/* eslint-disable max-classes-per-file */
import type {Store} from "redux";
import type {SagaMiddleware} from "redux-saga";
import * as Storage from "@atg-shared/storage";
import type {AtgRequestError, AtgResponse} from "@atg-shared/fetch-types";
import atgFetch from "@atg-shared/fetch";
import {ACCESS_TOKEN_STORAGE_KEY} from "./accessTokenConstants";
import authenticate, {type AuthStatus} from "./authenticate";
import refreshAccessToken from "./refreshAccessToken";
import {requestHadExpiredAccessToken} from "./requestHadExpiredAccessToken";
import {requestHadWrongAuthorizationScope} from "./requestHadWrongAuthorizationScope";
import {storeTimestamp, removeTimestamp} from "./authTimestamp";
import type {MemberFlowOptions} from "./authActions";

export class NoStoreForAuthenticationConfiguredError extends Error {}
export class AuthenticationCancelledError extends Error {}
export class NotAllowedToRetryRequestAfterScopeUpgrade extends Error {}
export class UserCancelledLogin extends Error {}

type SagaStore = Store<any, any> & {
    sagaMiddleware: SagaMiddleware;
};

let store: SagaStore | undefined;

/**
 * This configures the Redux store on which we'll run the sagas for the
 * authentication GUI flow and access token refresh.
 *
 * This function should be called in the entrypoint of each microFE (the
 * entrypoint for each microFE are in atg-micro-frontend/frontends directory).
 *
 * @param _store - The Redux store to run the sagas>
 */
export const configureStoreForAuthentication = (_store: SagaStore) => {
    store = _store;
};

const getStore = () => {
    if (!store) {
        throw new NoStoreForAuthenticationConfiguredError(
            "The Redux store to use for authentication has not been configured for this microFE.",
        );
    }

    return store;
};

const onScopeUpgrade = async (memberFlowOptions?: MemberFlowOptions) => {
    const result: AuthStatus = await getStore()
        .sagaMiddleware.run(authenticate, memberFlowOptions, true)
        .toPromise();

    if (result.status === "authCancelled") {
        throw new AuthenticationCancelledError("Authentication cancelled.");
    }
};

const onRefreshAccessToken = () =>
    getStore().sagaMiddleware.run(refreshAccessToken).toPromise();

/**
 * If non-null it means that there is a pending request to Token Handler for a
 * new access token. Used to perform request coalescion ("deduplication").
 */
let pendingTokenRefresh: Promise<void> | null = null;
/**
 * If non-null it means that there is a pending scope upgrade (login) to elevate
 * the user session to the required scope.
 */
let pendingScopeUpgrade: Promise<void> | null = null;

export type AuthOptions = {
    memberFlowEnabled?: boolean;
    memberFlowOptions?: MemberFlowOptions;
    fallbackUrl?: string;
    retryAfterScopeUpgrade?: boolean;
};

/**
 * Make an authenticated request to the given URL.
 *
 * - If the user doesn't have a valid access token it will first try to refresh
 *   it, and then retry the request.
 * - If it could not refresh the access token and the member flow option is
 *   enabled, it will show the Member Flow (login/registration modal) to the
 *   user so they can upgrade their scope, and then retry the request.
 * - If it could not refresh the access token and the member flow option is
 *   disabled, and a fallback URL is specified, it will fetch from the fallback
 *   URL.
 *
 * For details on how the authentication flow works, see
 * https://developer.atg.se/frontend/authentication/authentication.html
 *
 * In order for access token to be included into the headers, please add your URL to the list: packages/shared/atg-fetch/authorizationHeaderSupportedURLList.ts
 *
 * @param url - The URL to make the request to.
 * @param init - The request options to pass to the fetch function (which is
 * atgFetch compatible).
 * @param authOptions - Options for the authentication flow behaviour.
 * @param authOptions.memberFlowEnabled - Should we show the Member Flow
 * (login/registration modal) if the user is not in the required scope?
 * @param authOptions.memberFlowOptions - Options for the Member Flow
 * (login/registration modal).
 * @param authOptions.fallbackUrl - An optional URL to fetch if the user could
 * not fetch with a valid token and the Member Flow is not enabled.
 * @param authOptions.retryAfterScopeUpgrade - Should retry the request after
 * upgrading scope (e.g. after user has manually logged in).
 */
const fetchAuthorized = async <T>(
    ...args: [
        url: string,
        init?: RequestInit,
        authOptions?: AuthOptions,
        fetchFunction?: typeof atgFetch,
    ]
): Promise<AtgResponse<T>> => {
    let hasTriedRefreshingAccessToken = false;
    let hasTriedUpgradingScope = false;

    /**
     * The recursiveFetch function is essentially the same as the main fetchAuthorized. The reason for using a wrapped recursiveFetch function inside main fetchAuthorized
     * is to create a closure where we can store the `hasTriedRefreshingAccessToken` and `hasTriedUpgradingScope` variables in order to persist their values between invocations
     * of the recursiveFetch function.
     */
    const recursiveFetch = async (
        url: string,
        init?: RequestInit,
        authOptions: AuthOptions = {},
        fetchFunction: typeof atgFetch = atgFetch,
    ): Promise<AtgResponse<T>> => {
        const {
            memberFlowEnabled = true,
            memberFlowOptions,
            retryAfterScopeUpgrade = true,
        } = authOptions;

        // We could have a pending token refresh or scope upgrade if another request
        // has already triggered one, or we've recursed into this function (see
        // below), in that case we'll wait for that to finish before continuing so
        // we might get a valid token before we try the request, because the current
        // token could not be valid if it wasn't valid in the previous call.
        if (pendingTokenRefresh) {
            await pendingTokenRefresh.finally(() => {
                hasTriedRefreshingAccessToken = true;
                pendingTokenRefresh = null;
            });
        } else if (pendingScopeUpgrade) {
            await pendingScopeUpgrade.finally(() => {
                hasTriedUpgradingScope = true;
                pendingScopeUpgrade = null;
            });
        }

        if (hasTriedUpgradingScope && !retryAfterScopeUpgrade) {
            throw new NotAllowedToRetryRequestAfterScopeUpgrade(
                "Not allowed to retry the request after auth scope was upgraded.",
            );
        }

        const token = Storage.getItem(ACCESS_TOKEN_STORAGE_KEY) || "";

        try {
            const res = await fetchFunction<T>(url, init, token);
            if (res?.meta?.code === 200) {
                storeTimestamp();
            }
            return res;
        } catch (error: unknown) {
            // @ts-expect-error
            if (!error?.response) {
                throw error; // unexpected error that does not contain "response"
            }

            const {response: res} = error as AtgRequestError;
            const hasExpiredAccessToken = requestHadExpiredAccessToken(res);
            const hasWrongScope = requestHadWrongAuthorizationScope(res);
            const isAuthError = hasExpiredAccessToken || hasWrongScope;

            if (hasExpiredAccessToken && !hasTriedRefreshingAccessToken) {
                // There might already be a pending request to refresh the access
                // token triggered by an earlier request and in that case we don't
                // have to make the refresh request again. We'll wait for the
                // refresh request to finish after we have recursed into this
                // function.
                if (!pendingTokenRefresh) {
                    pendingTokenRefresh = onRefreshAccessToken();
                }

                // Recurse to restart the flow with a pending refresh.
                return recursiveFetch(url, init, authOptions, fetchFunction);
            }

            if (
                memberFlowEnabled &&
                !hasTriedUpgradingScope &&
                ((hasExpiredAccessToken && hasTriedRefreshingAccessToken) ||
                    hasWrongScope)
            ) {
                removeTimestamp();
                // This will trigger the login modal so that the user can
                // elevate their session to the required scope.
                //
                // There might already be a pending scope upgrade triggered by
                // an earlier request and in that case we don't want to show the
                // login modal again. We'll wait for the current scope upgrade
                // to finish after we have recursed into this function.
                if (!pendingScopeUpgrade) {
                    pendingScopeUpgrade = onScopeUpgrade(memberFlowOptions);
                }
                // Recurse to restart the flow with a pending scope upgrade.
                return recursiveFetch(url, init, authOptions, fetchFunction);
            }

            if (authOptions.fallbackUrl && isAuthError && !memberFlowEnabled) {
                const {fallbackUrl, ...restAuthOptions} = authOptions;
                return recursiveFetch(fallbackUrl, init, restAuthOptions, fetchFunction);
            }

            // Throw the error so the caller can handle it when we could not refresh
            // the access token or upgrade the scope, or if it was some other kind of error.
            throw error;
        }
    };

    return recursiveFetch(...args);
};

export default fetchAuthorized;
